import { map } from 'rxjs';
import moduleAuth from 'store/module-authorization';
import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';
import legacyId from 'pages/redirection/legacy/@@global/id';

import messages from '../loyalfans.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  parent: legacyId.earnMore,
  label: messages.pages.earnMore.loyalfans.navigationMenu.label,
  link: () => link.earnMore({ page: 'loyalfans' }),
  weight: 17,
  visibility$: authorization.getAuthorization$(id)?.pipe(map((page) => page && moduleAuth.hasLoyalfansPermission())),
};

export default menu;
