import { defer, iif, map, of } from 'rxjs';
import application from 'services/application';
import performer from 'store/performer';
import user from 'store/user';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';

import pageId from './id';

const authorization: RouteAuthorization = {
  name: pageId,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () =>
        iif(
          () => user.isModelView() && !user.hasLimitedAccess() && application.LiveJasmin,
          defer(() => performer.onStatusChange$.pipe(map(() => performer.hasActiveStatus()))),
          of(false)
        )
    )
  ),
};

export default authorization;
