import { defineMessages } from 'react-intl';

export default {
  pages: {
    help: {
      incomeShares: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.help.incomeShares.navigationMenu.label',
              defaultMessage: 'Income Shares',
            },
          }),
        },
        ...defineMessages({
          title: {
            id: 'pages.help.incomeShares.title',
            defaultMessage: 'Income Shares',
          },
          banner: {
            id: 'pages.help.incomeShares.banner',
            defaultMessage: 'Get up to <b>{percent}%</b> share!',
          },
        }),
        section1: {
          ...defineMessages({
            header: {
              id: 'pages.help.incomeShares.section1.header',
              defaultMessage: '{num}. Level Dependent Income & Shares',
            },
            body: {
              id: 'pages.help.incomeShares.section1.body',
              defaultMessage:
                'Your Online category level determines the income share you receive while using our online features; while your Offline category level determines your income share with our offline features. The more you use our features, the higher your level will be.',
            },
          }),
          features: {
            ...defineMessages({
              header: {
                id: 'pages.help.incomeShares.section1.features.header',
                defaultMessage: 'Level Dependent Income categories and features',
              },
            }),
            online: {
              ...defineMessages({
                header: {
                  id: 'pages.help.incomeShares.section1.features.online.header',
                  defaultMessage: 'Online',
                },
                subHeader: {
                  id: 'pages.help.incomeShares.section1.features.online.subHeader',
                  defaultMessage: 'Up to {percent}% share',
                },
              }),
              lists: {
                live: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.title',
                      defaultMessage: 'Live',
                    },
                    privateShow: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.privateShowLj',
                      defaultMessage: 'Private Show income',
                    },
                    privateSession: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.privateShowOranum',
                      defaultMessage: 'Private Session income',
                    },
                    surprisesLj: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.surprisesLj',
                      defaultMessage: 'Surprises during Free Chat / Private Show / Pre VIP Show',
                    },
                    surprisesOranum: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.surprisesOranum',
                      defaultMessage: 'Surprises during Free Chat / Private Session',
                    },
                    cam2cam: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.cam2cam',
                      defaultMessage: 'Cam2Cam',
                    },
                    twoWayAudio: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.twoWayAudio',
                      defaultMessage: 'Two-Way Audio',
                    },
                    snapshot: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.snapshot',
                      defaultMessage: 'Snapshot',
                    },
                    interactiveToy: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.interactiveToy',
                      defaultMessage: 'Interactive Toy income during Free Chat / Private Show / Pre VIP Show',
                    },
                    onlineTips: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.live.onlineTips',
                      defaultMessage: 'Online Tips during Free Chat / Private Show',
                    },
                  }),
                },
                videoCall: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.videoCall.title',
                      defaultMessage: 'Video Call',
                    },
                    callIncome: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.videoCall.callIncome',
                      defaultMessage: 'Video/Voice Call income',
                    },
                    surprises: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.videoCall.surprises',
                      defaultMessage: 'Surprises during Video Call',
                    },
                    onlineTips: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.videoCall.onlineTips',
                      defaultMessage: 'Online Tips during Video Call',
                    },
                    interactiveToy: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.videoCall.interactiveToy',
                      defaultMessage: 'Interactive Toy income',
                    },
                  }),
                },
                actionRequests: {
                  ...defineMessages({
                    titleLj: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.actionRequests.titleLj',
                      defaultMessage: 'Action Requests',
                    },
                    titleOranum: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.actionRequests.titleOranum',
                      defaultMessage: 'Special Requests',
                    },
                    freeChat: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.actionRequests.freeChat',
                      defaultMessage: 'Action Request during Free Chat',
                    },
                    privateShowLj: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.actionRequests.privateShowLj',
                      defaultMessage: 'Action Request during Free Chat / Private Show',
                    },
                    privateShowOranum: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.actionRequests.privateShowOranum',
                      defaultMessage: 'Special requests during Free Chat / Private Session',
                    },
                  }),
                },
                vipShow: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.vipShow.title',
                      defaultMessage: 'VIP Show',
                    },
                    buyIn: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.vipShow.buyIn',
                      defaultMessage: 'VIP Show buy-in',
                    },
                    surprises: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.vipShow.surprises',
                      defaultMessage: 'Surprises during VIP Show',
                    },
                    interactiveToy: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.vipShow.interactiveToy',
                      defaultMessage: 'Interactive Toy income',
                    },
                  }),
                },
                scheduledShows: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.scheduledShows.title',
                      defaultMessage: 'Scheduled Shows',
                    },
                    buyIn: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.scheduledShows.buyIn',
                      defaultMessage: 'Scheduled Show buy-in',
                    },
                    surprises: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.scheduledShows.surprises',
                      defaultMessage: 'Surprises during Scheduled Show',
                    },
                    interactiveToy: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.scheduledShows.interactiveToy',
                      defaultMessage: 'Interactive Toy income',
                    },
                  }),
                },
                groupSession: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.groupSession.title',
                      defaultMessage: 'Group Session',
                    },
                    buyIn: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.groupSession.buyIn',
                      defaultMessage: 'Group Session buy-in',
                    },
                    surprises: {
                      id: 'pages.help.incomeShares.section1.features.online.lists.groupSession.surprises',
                      defaultMessage: 'Surprises during Group Session',
                    },
                  }),
                },
              },
            },
            offline: {
              ...defineMessages({
                header: {
                  id: 'pages.help.incomeShares.section1.features.offline.header',
                  defaultMessage: 'Offline',
                },
                subHeader: {
                  id: 'pages.help.incomeShares.section1.features.offline.subHeader',
                  defaultMessage: 'Up to {percent}% share',
                },
              }),
              lists: {
                messenger: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.messenger.title',
                      defaultMessage: 'Messenger',
                    },
                    paying: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.messenger.paying',
                      defaultMessage: 'Paid messages',
                    },
                    story: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.messenger.story',
                      defaultMessage: 'Story reactions',
                    },
                    video: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.messenger.video',
                      defaultMessage: 'Premium Video purchases on Messenger',
                    },
                    photo: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.messenger.photo',
                      defaultMessage: 'Premium Photo purchases on Messenger',
                    },
                  }),
                },
                surprises: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.surprises.title',
                      defaultMessage: 'Offline Surprises',
                    },
                    any: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.surprises.any',
                      defaultMessage: 'Any offline surprises',
                    },
                  }),
                },
                tips: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.tips.title',
                      defaultMessage: 'Offline Tips',
                    },
                    any: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.tips.any',
                      defaultMessage: 'Any offline Tips',
                    },
                  }),
                },
                stories: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.stories.title',
                      defaultMessage: 'Stories',
                    },
                    subscriptions: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.stories.subscriptions',
                      defaultMessage: 'Subscriptions to Stories',
                    },
                  }),
                },
                fanClub: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.fanClub.title',
                      defaultMessage: 'Fan Club',
                    },
                    subscriptions: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.fanClub.subscriptions',
                      defaultMessage: 'Fan Club subscriptions',
                    },
                  }),
                },
                videos: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.videos.title',
                      defaultMessage: 'Premium Videos',
                    },
                    myContent: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.videos.myContent',
                      defaultMessage: 'My Content Premium Video purchases',
                    },
                  }),
                },
                photos: {
                  ...defineMessages({
                    title: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.photos.title',
                      defaultMessage: 'Premium Photos',
                    },
                    myContent: {
                      id: 'pages.help.incomeShares.section1.features.offline.lists.photos.myContent',
                      defaultMessage: 'My Content Premium Photo album purchases',
                    },
                  }),
                },
              },
            },
          },
          incomeLevels: {
            ...defineMessages({
              header: {
                id: 'pages.help.incomeShares.section1.incomeLevels.header',
                defaultMessage: 'Income levels and shares',
              },
            }),
            headers: {
              ...defineMessages({
                level: {
                  id: 'pages.help.incomeShares.section1.incomeLevels.headers.level',
                  defaultMessage: 'Level',
                },
                earnings: {
                  id: 'pages.help.incomeShares.section1.incomeLevels.headers.earnings',
                  defaultMessage: 'Earnings',
                },
              }),
            },
            ...defineMessages({
              levelNum: {
                id: 'pages.help.incomeShares.section1.incomeLevels.levelNum',
                defaultMessage: 'LVL {num}',
              },
            }),
            helpList: {
              ...defineMessages({
                item1: {
                  id: 'pages.help.incomeShares.section1.incomeLevels.helpList.item1',
                  defaultMessage: 'Your income level resets to LVL {num} at the beginning of every period.',
                },
                item2: {
                  id: 'pages.help.incomeShares.section1.incomeLevels.helpList.item2',
                  defaultMessage: 'Private Shows launched outside of Free Chat have a {percent}% lower commission.',
                },
              }),
            },
          },
        },
        section2: {
          ...defineMessages({
            header: {
              id: 'pages.help.incomeShares.section2.header',
              defaultMessage: '{num}. Level Independent Income & Shares',
            },
            body: {
              id: 'pages.help.incomeShares.section2.body',
              defaultMessage: 'Certain features will not increase your income level and have a fixed share.',
            },
          }),
          features: {
            ...defineMessages({
              header: {
                id: 'pages.help.incomeShares.section2.features.header',
                defaultMessage: 'Level independent features and shares',
              },
              awardsTitle: {
                id: 'pages.help.incomeShares.section2.features.awardsTitle',
                defaultMessage: 'Awards',
              },
              awardsBody: {
                id: 'pages.help.incomeShares.section2.features.awardsBody',
                defaultMessage: '{percent}% Fixed share',
              },
              modelReferralTitle: {
                id: 'pages.help.incomeShares.section2.features.modelReferralTitle',
                defaultMessage: 'Model Referral',
              },
              modelReferralBody: {
                id: 'pages.help.incomeShares.section2.features.modelReferralBody',
                defaultMessage: '{percent}% Fixed share',
              },
              memberReferralTitle: {
                id: 'pages.help.incomeShares.section2.features.memberReferralTitle',
                defaultMessage: 'Member Referral',
              },
              memberReferralBody: {
                id: 'pages.help.incomeShares.section2.features.memberReferralBody',
                defaultMessage: '{percent}% Fixed share',
              },
              referralsTitle: {
                id: 'pages.help.incomeShares.section2.features.referralsTitle',
                defaultMessage: 'Referrals',
              },
              referralsBody: {
                id: 'pages.help.incomeShares.section2.features.referralsBody',
                defaultMessage: '{percent}% Fixed share',
              },
              realGiftsTitle: {
                id: 'pages.help.incomeShares.section2.features.realGiftsTitle',
                defaultMessage: 'Real Gifts',
              },
              realGiftsBody: {
                id: 'pages.help.incomeShares.section2.features.realGiftsBody',
                defaultMessage: '{percent}% Fixed share',
              },
              bonusesTitle: {
                id: 'pages.help.incomeShares.section2.features.bonusesTitle',
                defaultMessage: 'Bonuses',
              },
              bonusesBody: {
                id: 'pages.help.incomeShares.section2.features.bonusesBody',
                defaultMessage: 'One time income',
              },
            }),
          },
          shares: {
            ...defineMessages({
              header: {
                id: 'pages.help.incomeShares.section2.shares.header',
                defaultMessage: 'Do you want to reach <b>{percent}%</b> income share?',
              },
              body: {
                id: 'pages.help.incomeShares.section2.shares.body',
                defaultMessage: 'Use <b>Member Referral</b> and add an <b>extra {percent}%</b> to your income!',
              },
              cta: {
                id: 'pages.help.incomeShares.section2.shares.cta',
                defaultMessage: 'Check it <a>here</a>.',
              },
            }),
          },
        },
        faq: {
          ...defineMessages({
            header: {
              id: 'pages.help.incomeShares.faq.header',
              defaultMessage: 'Frequently Asked Questions',
            },
            paymentSystemTitle: {
              id: 'pages.help.incomeShares.faq.paymentSystemTitle',
              defaultMessage: 'What is the Level Dependent Payment System?',
            },
            paymentSystemBody1: {
              id: 'pages.help.incomeShares.faq.paymentSystemBody1',
              defaultMessage:
                'Your earnings are based on our Level Dependent Payment System, which lets you increase your earnings without raising your price.',
            },
            paymentSystemBody2: {
              id: 'pages.help.incomeShares.faq.paymentSystemBody2',
              defaultMessage:
                'Your commission for features under our Level Dependent Income system will vary depending on your current level. The higher your level is, the higher your commission will be.',
            },
            levelsTitle: {
              id: 'pages.help.incomeShares.faq.levelsTitle',
              defaultMessage: 'What are these levels?',
            },
            levelsBody: {
              id: 'pages.help.incomeShares.faq.levelsBody',
              defaultMessage:
                'There are {num} levels in the new payment structure - each with a different commission rate. The higher the level you reach during a period, the higher the percentage you will receive from your Level Dependent income.',
            },
            categoriesTitle: {
              id: 'pages.help.incomeShares.faq.categoriesTitle',
              defaultMessage: 'Can I have different levels for the Online and Offline categories?',
            },
            categoriesBodyLj: {
              id: 'pages.help.incomeShares.faq.categoriesBodyLj',
              defaultMessage:
                'Yes, of course. For example, you could reach level {levelOnline} in the Online category and level {levelOffline} in the Offline category. Your earnings with online features contribute to your Online category level; while your earnings with offline features contribute to your Offline category level. Some models are better at using Online features like Private Show, VIP Shows... while some are great at using Offline features like Messenger and Stories! The more you use our features, the higher your level will be.',
            },
            categoriesBodyOranum: {
              id: 'pages.help.incomeShares.faq.categoriesBodyOranum',
              defaultMessage:
                'Yes, of course. For example, you could reach level 4 in the Online category and level 6 in the Offline category. Your earnings with online features contribute to your Online category level; while your earnings with offline features contribute to your Offline category level. Some experts are better at using Online features like Private Session, Video Call... while some are great at using Offline features like Messenger and Stories! The more you use our features, the higher your level will be.',
            },
            chargeBacksTitle: {
              id: 'pages.help.incomeShares.faq.chargeBacksTitle',
              defaultMessage: 'Do charge-backs affect my level?',
            },
            chargeBacksBody: {
              id: 'pages.help.incomeShares.faq.chargeBacksBody',
              defaultMessage: 'Charge-backs do not affect your current level.',
            },
            privateShowTitle: {
              id: 'pages.help.incomeShares.faq.privateShowTitle',
              defaultMessage: 'Can I go up a level during a Private show?',
            },
            privateShowBody: {
              id: 'pages.help.incomeShares.faq.privateShowBody',
              defaultMessage:
                'Your commission for a Private Show is based on your level for the Online category at the time the given show begins. This means that even if you reach a higher level during the show, it will be calculated according to the previous level.',
            },
            privateSessionTitle: {
              id: 'pages.help.incomeShares.faq.privateSessionTitle',
              defaultMessage: 'Can I go up a level during a Private Session?',
            },
            privateSessionBody: {
              id: 'pages.help.incomeShares.faq.privateSessionBody',
              defaultMessage:
                'Your commission for a Private Session is based on your level for the Online category at the time the given session begins. This means that even if you reach a higher level during the session, it will be calculated according to the previous level.',
            },
            earningsTitle: {
              id: 'pages.help.incomeShares.faq.earningsTitle',
              defaultMessage:
                'What happens if I have pending or unpaid earnings from the previous period? Is it added to the current one to help me reach a higher level?',
            },
            earningsBody: {
              id: 'pages.help.incomeShares.faq.earningsBody',
              defaultMessage:
                'Unfortunately, it is not. You start every period with {percent}% even if you have pending payments from the previous period.',
            },
            exampleTitle: {
              id: 'pages.help.incomeShares.faq.exampleTitle',
              defaultMessage: 'Can you give an example?',
            },
            exampleBodyLj: {
              id: 'pages.help.incomeShares.faq.exampleBodyLj',
              defaultMessage:
                "Of course! Let's assume that you earned {earnings} in a period for the Online category. This means that you are at level {level} ({levelMin} - {levelMax}) in the Online category and you will receive {percent}% of your earnings for the features which are included in this category. So if you have a {perMinute} per minute rate and someone takes you to a five-minute Private Show, your total earnings for the Private Show will be: {perMinute} x {numMinutes} x {percent}% = {showEarnings} (After this Private Show your total income for the Online category would be {totalEarnings}). Please note that this percentage only applies to income received on this level. Income earned on a lower level will result in lower commissions.",
            },
            exampleBodyOranum: {
              id: 'pages.help.incomeShares.faq.exampleBodyOranum',
              defaultMessage:
                "Of course! Let's assume that you earned {earnings} in a period for the Online category. This means that you are at level {level} ({levelMin} - {levelMax}) in the Online category and you will receive {percent}% of your earnings for the features which are included in this category. So if you have a {perMinute} per minute rate and someone takes you to a five-minute Private Session, your total earnings for the Private Session will be: {perMinute} x {numMinutes} x {percent}% = {showEarnings} (After this Private Session your total income for the Online category would be {totalEarnings}). Please note that this percentage only applies to income received on this level. Income earned on a lower level will result in lower commissions.",
            },
          }),
        },
      },
    },
  },
};
