import { defineMessages } from 'react-intl';

export default {
  pages: {
    earnMore: {
      loyalfans: {
        seo: defineMessages({
          title: {
            id: 'pages.earnMore.loyalfans.seo.title',
            defaultMessage: 'Loyalfans',
          },
          description: {
            id: 'pages.earnMore.loyalfans.seo.description',
            defaultMessage: 'Loyalfans',
          },
        }),
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.earnMore.loyalfans.navigationMenu.label',
              defaultMessage: 'Loyalfans',
            },
          }),
        },
        ...defineMessages({
          createCta: {
            id: 'pages.earnMore.loyalfans.createCta',
            defaultMessage: 'Create a Loyalfans profile',
          },
        }),
        errors: defineMessages({
          unexpected: {
            id: 'pages.earnMore.loyalfans.errors.unexpected',
            defaultMessage: 'An error occurred. Please, reload the page or try again later.',
          },
        }),
      },
    },
  },
};
