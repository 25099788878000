import { defer, of } from 'rxjs';
import performer from 'store/performer';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';

import pageId from './id';

const authorization: RouteAuthorization = {
  name: pageId,
  authorization$: defer(() =>
    performer.onChange$.pipe(
      evalAuthorization(
        ({ id }) => is.nullish(id),
        () => of(!performer.hasAmateurCategory())
      )
    )
  ),
};

export default authorization;
