import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.news.seo.livejasmin.title',
      defaultMessage: 'News',
    },
    description: {
      id: 'pages.news.seo.livejasmin.description',
      defaultMessage: 'News',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.news.seo.oranum.title',
      defaultMessage: 'News',
    },
    description: {
      id: 'pages.news.seo.oranum.description',
      defaultMessage: 'News',
    },
  }),
};

export default {
  pages: {
    news: {
      seo,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.news.navigationMenu.label',
            defaultMessage: 'News',
          },
        }),
      },
      notFound: {
        ...defineMessages({
          title: {
            id: 'pages.news.notFound.title',
            defaultMessage: 'No results found',
          },
          info: {
            id: 'pages.news.notFound.info',
            defaultMessage: 'Check your search for any typos or try a different search.',
          },
        }),
      },
      showMore: {
        ...defineMessages({
          title: {
            id: 'pages.news.showMore.title',
            defaultMessage: 'Show more',
          },
        }),
      },
    },
  },
};
