import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import(/* webpackPreload: true */ '../not-found'));

const routes: Route[] = [
  {
    name: 'notFoundCatchAll',
    path: '*',
    replace: true,
    element: <AsyncPage />,
    layout: false,
  },
  {
    name: id,
    path: '/:lang?/not-found',
    replace: true,
    element: <AsyncPage />,
    layout: false,
  },
];

export default routes;
