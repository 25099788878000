import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../news'));
const AsyncSkeletonPage = React.lazy(() => import('../news.skeleton'));

const routes: Route[] = [
  {
    name: id,
    path: '/:lang?/help/news',
    searchParams: [':search'],
    element: <AsyncPage />,
    skeleton: <AsyncSkeletonPage />,
  },
];

export default routes;
