import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../loyalfans'));
// eslint-disable-next-line import/extensions
const AsyncSkeletonPage = React.lazy(() => import('pages/loyalfans/loyalfans.skeleton'));

const routes: Route = {
  name: id,
  path: '/:lang?/earn-more/loyalfans',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
